

const jsIndex = {

    init : function () {

    }
}


$(function () {

    jsIndex.init();
});




